import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTextHighlighter = _resolveComponent("AppTextHighlighter")
  const _component_AppPhoneNumber = _resolveComponent("AppPhoneNumber")
  const _component_ClientTableDetails = _resolveComponent("ClientTableDetails")
  const _component_DmAsyncContent = _resolveComponent("DmAsyncContent")
  const _component_DmTable = _resolveComponent("DmTable")

  return (_openBlock(), _createBlock(_component_DmTable, {
    class: _ctx.$bem({}),
    headers: _ctx.headers,
    items: _ctx.tableItems,
    "fixed-header": "",
    padding: "lg",
    expandable: "",
    "expand-on-row-click": "",
    "data-test": "clients-table",
    onExpandItem: _ctx.expandClientDetails
  }, {
    "item-name": _withCtx(({field}) => [
      _createVNode(_component_AppTextHighlighter, {
        text: field || '——— ———',
        pattern: _ctx.searchText
      }, null, 8, ["text", "pattern"])
    ]),
    "item-phone": _withCtx(({field}) => [
      _createVNode(_component_AppPhoneNumber, {
        value: field,
        "highlight-pattern": _ctx.searchText
      }, null, 8, ["value", "highlight-pattern"])
    ]),
    "item-email": _withCtx(({field}) => [
      _createVNode(_component_AppTextHighlighter, {
        text: field,
        pattern: _ctx.searchText
      }, null, 8, ["text", "pattern"])
    ]),
    "expanded-item": _withCtx(() => [
      _createVNode(_component_DmAsyncContent, {
        loading: _ctx.isClientLoading,
        error: _ctx.isClientError,
        reloadable: "",
        onReload: _ctx.fetchClientDetails
      }, {
        default: _withCtx(() => [
          (_ctx.expandedClient)
            ? (_openBlock(), _createBlock(_component_ClientTableDetails, {
                key: 0,
                class: _ctx.$bem({e: 'item-details'}),
                client: _ctx.expandedClient,
                "search-text": _ctx.searchText,
                onEditClient: _ctx.onClientEdit,
                onCreateCar: _ctx.fetchClientDetails,
                onEditCar: _ctx.fetchClientDetails,
                onCreateRepair: _ctx.fetchClientDetails,
                onEditRepair: _ctx.fetchClientDetails,
                onClose: _ctx.fetchClientDetails
              }, null, 8, ["class", "client", "search-text", "onEditClient", "onCreateCar", "onEditCar", "onCreateRepair", "onEditRepair", "onClose"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["loading", "error", "onReload"])
    ]),
    _: 1
  }, 8, ["class", "headers", "items", "onExpandItem"]))
}