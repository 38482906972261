
import { defineComponent, PropType, inject } from 'vue';
import {
  Client,
  Car,
  ClientCar,
  ClientCarRepair
} from '@/api/models';
import {
  DmButton,
  DmSelect
} from '@dobrymechanik/vue-ui';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import {
  AppTextHighlighter,
  AppPhoneNumber,
  AppCarEditModal,
  AppCarCreateModal,
  AppEventModal
} from '@/components';
import ClientsTableDetailsEdit from './Edit';
import { mapState } from 'vuex';

const allCars = 'all';

export default defineComponent({
  name: 'ClientsTableDetails',
  components: {
    DmButton,
    DmSelect,
    AppTextHighlighter,
    AppPhoneNumber,
    AppCarEditModal,
    AppCarCreateModal,
    AppEventModal,
    ClientsTableDetailsEdit
  },
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true
    },
    searchText: {
      type: String as PropType<string>,
      required: true
    }
  },
  emits: [
    'show-repair',
    'create-repair',
    'edit-repair',
    'create-car',
    'edit-client',
    'edit-car',
    'close'
  ],
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      selectedRepairCars: allCars as string,
      showAllRepairs: false as boolean,
      isNewRepairModalShown: false as boolean,
      isEditClientModalShown: false as boolean,
      isCarEditModalShown: false as boolean,
      carToEdit: null as Car | null,
      isEventModalActive: false as boolean,
      eventId: null as string | null,
      isNewCarModalShown: false as boolean
    };
  },
  computed: {
    ...mapState('garage', {
      garageName: 'name'
    }),
    filteredRepairs (): any[] {
      const allRepairs: ClientCarRepair[] = this.client.repairs || [];
      return allRepairs
        .filter(r => [allCars, r.carId].includes(this.selectedRepairCars))
        .map(r => {
          const car = this.client.cars?.find(c => c.id === r.carId) || null;
          return {
            ...r,
            licensePlateNumber: car?.licensePlateNumber || null,
            car: car ? `${car.brand} ${car.model}` : ''
          };
        })
        .sort((prev, current) => {
          const getDate = (str: string | null) => str ? Date.parse(str) : 0;
          return getDate(current.date) - getDate(prev.date);
        });
    },
    repairs (): any[] {
      return this.showAllRepairs ? this.filteredRepairs : this.filteredRepairs.slice(0, 3);
    },
    carsToSelect (): any[] {
      return [
        {
          key: allCars,
          text: this.$t('clients.repairs.allCars')
        },
        ...this.client.cars
          .map(c => ({
            key: c.id,
            text: `${c.brand} ${c.model}, ${c.licensePlateNumber}`
          }))
      ];
    },
    isShowAllRepairsButtonVisible (): boolean {
      return !this.showAllRepairs && this.filteredRepairs.length > 3;
    }
  },
  methods: {
    formatDate (date: string): string {
      return date ? new Date(date).toLocaleDateString(this.$i18n.locale, { day: '2-digit', month: '2-digit', year: 'numeric' }) : '';
    },
    openNewRepairModal (): void {
      this.isNewRepairModalShown = true;
    },
    openNewCarModal (): void {
      this.isNewCarModalShown = true;
    },
    createRepair (): void {
      this.$emit('create-repair');
    },
    createCar (): void {
      this.$emit('create-car');
    },
    openEditClientModal (): void {
      this.isEditClientModalShown = true;
    },
    onClientEdit (): void {
      this.$emit('edit-client');
    },
    onCarEdit (): void {
      this.$emit('edit-car');
    },
    onClose (): void {
      this.$emit('close');
    },
    openCarEditModal (car: ClientCar): void {
      this.isCarEditModalShown = true;
      const { id, brandId, brand, model, modelId, vin, licensePlateNumber, tecDocMeta } = car;
      this.carToEdit = { id, brandId, brand, model, modelId, vin, licensePlateNumber, tecDocMeta };
    },
    showRepair (id: string): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.CLIENTS,
        action: 'Client list repair clicked'
      });
      this.eventId = id;
      this.isEventModalActive = true;
    },
    closeRepair (): void {
      this.eventId = null;
      this.isEventModalActive = false;
    },
    onRepairEdit (): void {
      this.$emit('edit-repair');
    }
  }
});
