
import { defineComponent, PropType } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppClientForm,
  AppModalButton
} from '@/components';
import { jsonToURLSearchParams } from '@/helpers/requests';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { URI_CLIENT } from '@/api/endpoints';
import {
  Client
} from '@/api/models';
import { ClientFormData } from '@/models/clients';

export default defineComponent({
  name: 'ClientsTableDetailsEdit',
  components: {
    AppModal,
    AppModalHeader,
    AppClientForm,
    AppModalButton
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    client: {
      type: Object as PropType<Client>,
      required: true
    }
  },
  emits: [
    'close',
    'update:modelValue',
    'edit-client'
  ],
  data: function () {
    return {
      loading: false as boolean
    };
  },
  computed: {
    initialValues (): ClientFormData {
      return {
        name: this.client.name || null,
        phone: this.client.phone,
        email: this.client.email,
        marketingConsent: this.client.marketingConsent
      };
    }
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    close (): void {
      this.$emit('close');
      this.$emit('update:modelValue', false);
    },
    async submit (values: any): Promise<void> {
      this.loading = true;
      const data = jsonToURLSearchParams({
        ...values,
        marketingConsent: values.marketingConsent ? 1 : 0
      });
      try {
        await this.$http.patch(URI_CLIENT(this.client.id), data);
        this.createNotification({
          message: this.$t('clients.edit.success'),
          type: 'success'
        });
        this.$emit('edit-client');
        this.close();
      } catch (e) {
        this.createNotification({
          message: this.$t('clients.edit.error'),
          type: 'error'
        });
      }
      this.loading = false;
    }
  }
});
