import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/dm-logo-blue.png'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppPhoneNumber = _resolveComponent("AppPhoneNumber")
  const _component_AppTextHighlighter = _resolveComponent("AppTextHighlighter")
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_ClientsTableDetailsEdit = _resolveComponent("ClientsTableDetailsEdit")
  const _component_AppCarEditModal = _resolveComponent("AppCarEditModal")
  const _component_AppCarCreateModal = _resolveComponent("AppCarCreateModal")
  const _component_DmSelect = _resolveComponent("DmSelect")
  const _component_AppEventModal = _resolveComponent("AppEventModal")

  return (_openBlock(), _createBlock("div", {
    class: _ctx.$bem({}),
    "data-test": "client-details"
  }, [
    _createVNode("div", {
      class: _ctx.$bem({ e: 'left-side' })
    }, [
      _createVNode("div", {
        class: _ctx.$bem({ e: 'section-header' })
      }, [
        _createVNode("h3", {
          class: _ctx.$bem({ e: 'section-title' })
        }, _toDisplayString(_ctx.$t('clients.client.title')), 3)
      ], 2),
      _createVNode("div", {
        class: _ctx.$bem({ e: 'field' })
      }, [
        _createVNode("label", {
          class: _ctx.$bem({ e: 'field-label' })
        }, _toDisplayString(_ctx.$t('clients.fields.phone')), 3),
        _createVNode("div", {
          class: _ctx.$bem({ e: 'field-value' })
        }, [
          _createVNode(_component_AppPhoneNumber, {
            value: _ctx.client.phone,
            "highlight-pattern": _ctx.searchText
          }, null, 8, ["value", "highlight-pattern"])
        ], 2)
      ], 2),
      _createVNode("div", {
        class: _ctx.$bem({ e: 'field' })
      }, [
        _createVNode("label", {
          class: _ctx.$bem({ e: 'field-label' })
        }, _toDisplayString(_ctx.$t('clients.fields.name')), 3),
        _createVNode("div", {
          class: _ctx.$bem({ e: 'field-value' })
        }, [
          _createVNode(_component_AppTextHighlighter, {
            text: _ctx.client.name || '——— ———',
            pattern: _ctx.searchText
          }, null, 8, ["text", "pattern"])
        ], 2)
      ], 2),
      _createVNode("div", {
        class: _ctx.$bem({ e: 'field' })
      }, [
        _createVNode("label", {
          class: _ctx.$bem({ e: 'field-label' })
        }, _toDisplayString(_ctx.$t('clients.fields.email')), 3),
        _createVNode("div", {
          class: _ctx.$bem({ e: 'field-value' })
        }, [
          _createVNode(_component_AppTextHighlighter, {
            text: _ctx.client.email,
            pattern: _ctx.searchText
          }, null, 8, ["text", "pattern"])
        ], 2)
      ], 2),
      _createVNode("div", {
        class: _ctx.$bem({ e: 'field' })
      }, [
        _createVNode("label", {
          class: _ctx.$bem({ e: 'field-label' })
        }, _toDisplayString(_ctx.$t('clients.fields.marketingConsent', { garage: _ctx.garageName })), 3),
        _createVNode("div", {
          class: _ctx.$bem({ e: 'field-value' })
        }, _toDisplayString(_ctx.client.marketingConsent !== null ? (_ctx.client.marketingConsent ? _ctx.$t('common.yes') : _ctx.$t('common.no')) :
            _ctx.$t('common.notKnown')), 3)
      ], 2),
      _createVNode(_component_DmButton, {
        plain: "",
        hoverable: "",
        bordered: "",
        rounded: "",
        color: "primary",
        class: _ctx.$bem({ e: 'edit-client' }),
        onClick: _ctx.openEditClientModal
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.edit')), 1)
        ]),
        _: 1
      }, 8, ["class", "onClick"]),
      _createVNode(_component_ClientsTableDetailsEdit, {
        modelValue: _ctx.isEditClientModalShown,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditClientModalShown = $event)),
        client: _ctx.client,
        onEditClient: _ctx.onClientEdit
      }, null, 8, ["modelValue", "client", "onEditClient"])
    ], 2),
    _createVNode("div", {
      class: _ctx.$bem({ e: 'right-side' })
    }, [
      _createVNode("div", {
        class: _ctx.$bem({ e: 'section' })
      }, [
        _createVNode("div", {
          class: _ctx.$bem({ e: 'section-header' })
        }, [
          _createVNode("h3", {
            class: _ctx.$bem({ e: 'section-title' })
          }, _toDisplayString(_ctx.$t('clients.cars.title')), 3)
        ], 2),
        _createVNode("div", {
          class: _ctx.$bem({ e: 'cars' })
        }, [
          (_ctx.client.cars.length === 0)
            ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('clients.cars.noItems')), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.client.cars, (car) => {
            return (_openBlock(), _createBlock("div", {
              key: car.id,
              class: _ctx.$bem({ e: 'car' })
            }, [
              _createVNode("div", {
                class: _ctx.$bem({ e: 'car-field' })
              }, _toDisplayString(car.brand) + " " + _toDisplayString(car.model), 3),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'car-field' })
              }, [
                _createVNode(_component_AppTextHighlighter, {
                  text: car.licensePlateNumber,
                  pattern: _ctx.searchText
                }, null, 8, ["text", "pattern"])
              ], 2),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'car-field' })
              }, _toDisplayString(car.vin), 3),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'car-actions' })
              }, [
                _createVNode(_component_DmButton, {
                  rounded: "",
                  plain: "",
                  color: "primary",
                  class: _ctx.$bem({ e: 'edit-car' }),
                  onClick: ($event: any) => (_ctx.openCarEditModal(car))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.edit')), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"])
              ], 2)
            ], 2))
          }), 128)),
          (_ctx.carToEdit)
            ? (_openBlock(), _createBlock(_component_AppCarEditModal, {
                key: 1,
                modelValue: _ctx.isCarEditModalShown,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isCarEditModalShown = $event)),
                details: _ctx.carToEdit,
                onEdit: _ctx.onCarEdit,
                onClose: _ctx.onClose
              }, null, 8, ["modelValue", "details", "onEdit", "onClose"]))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode("div", {
          class: _ctx.$bem({ e: 'add-car' })
        }, [
          _createVNode(_component_DmButton, {
            rounded: "",
            dark: "",
            color: "primary",
            onClick: _ctx.openNewCarModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('cars.add')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_AppCarCreateModal, {
            modelValue: _ctx.isNewCarModalShown,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isNewCarModalShown = $event)),
            "client-id": _ctx.client.id,
            onCreate: _ctx.createCar
          }, null, 8, ["modelValue", "client-id", "onCreate"])
        ], 2)
      ], 2),
      _createVNode("div", {
        class: _ctx.$bem({ e: 'section' })
      }, [
        _createVNode("div", {
          class: _ctx.$bem({ e: 'section-header' })
        }, [
          _createVNode("h3", {
            class: _ctx.$bem({ e: 'section-title' })
          }, _toDisplayString(_ctx.$t('clients.repairs.title')), 3),
          _createVNode(_component_DmSelect, {
            modelValue: _ctx.selectedRepairCars,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectedRepairCars = $event)),
            items: _ctx.carsToSelect,
            rounded: "",
            class: _ctx.$bem({ e: 'select-repair-cars' })
          }, null, 8, ["modelValue", "items", "class"])
        ], 2),
        _createVNode("div", {
          class: _ctx.$bem({ e: 'repairs' })
        }, [
          _createVNode(_component_AppEventModal, {
            id: _ctx.eventId,
            modelValue: _ctx.isEventModalActive,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.isEventModalActive = $event)),
            "dynamic-header": "",
            onClose: _ctx.closeRepair,
            onStatusChange: _ctx.onRepairEdit,
            onEventCancel: _ctx.onRepairEdit,
            onEdit: _ctx.onRepairEdit
          }, null, 8, ["id", "modelValue", "onClose", "onStatusChange", "onEventCancel", "onEdit"]),
          (_ctx.repairs.length === 0)
            ? (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('clients.repairs.noItems')), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.repairs, (repair) => {
            return (_openBlock(), _createBlock("div", {
              key: repair.id,
              class: _ctx.$bem({ e: 'repair' })
            }, [
              _createVNode("div", {
                class: _ctx.$bem({ e: 'repair-date' })
              }, _toDisplayString(_ctx.formatDate(repair.date)), 3),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'repair-field' })
              }, _toDisplayString(repair.car), 3),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'repair-field' })
              }, [
                _createVNode(_component_AppTextHighlighter, {
                  text: repair.licensePlateNumber,
                  pattern: _ctx.searchText
                }, null, 8, ["text", "pattern"])
              ], 2),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'repair-reason' })
              }, _toDisplayString(repair.reason), 3),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'repair-source' })
              }, [
                (repair.isExternal)
                  ? (_openBlock(), _createBlock("img", {
                      key: 0,
                      class: _ctx.$bem({ e: 'repair-source-content' }),
                      src: _imports_0,
                      "data-tippy-content": _ctx.$t('calendar.event.sourceDM'),
                      "data-tippy-placement": "left"
                    }, null, 10, ["data-tippy-content"]))
                  : (_openBlock(), _createBlock("div", {
                      key: 1,
                      class: _ctx.$bem({ e: 'repair-source-content' })
                    }, null, 2))
              ], 2),
              _createVNode("div", {
                class: _ctx.$bem({ e: 'repair-actions' })
              }, [
                _createVNode(_component_DmButton, {
                  rounded: "",
                  plain: "",
                  color: "primary",
                  class: _ctx.$bem({ e: 'show-repair' }),
                  onClick: ($event: any) => (_ctx.showRepair(repair.id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.details')), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"])
              ], 2)
            ], 2))
          }), 128))
        ], 2),
        (_ctx.isShowAllRepairsButtonVisible)
          ? (_openBlock(), _createBlock(_component_DmButton, {
              key: 0,
              rounded: "",
              plain: "",
              hoverable: "",
              color: "primary",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showAllRepairs = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('clients.repairs.showAll')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ], 2))
}