
import { defineComponent, PropType } from 'vue';
import {
  AppModal,
  AppModalHeader,
  AppClientForm,
  AppModalButton
} from '@/components';
import { jsonToURLSearchParams } from '@/helpers/requests';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { URI_CLIENTS } from '@/api/endpoints';
import { ClientFormData } from '@/models/clients';

const initialValues: ClientFormData = {
  name: null,
  phone: null,
  email: null,
  marketingConsent: null
};

export default defineComponent({
  name: 'ClientsNewModal',
  components: {
    AppModal,
    AppModalHeader,
    AppClientForm,
    AppModalButton
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  emits: [
    'close',
    'update:modelValue',
    'create-client'
  ],
  data: function () {
    return {
      loading: false as boolean,
      initialValues
    };
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    close (): void {
      this.initialValues = initialValues;
      this.$emit('close');
      this.$emit('update:modelValue', false);
    },
    async submit (values: any): Promise<void> {
      this.loading = true;
      const data = jsonToURLSearchParams({
        ...values,
        marketingConsent: values.marketingConsent ? 1 : 0
      });
      try {
        await this.$http.post(URI_CLIENTS, data);
        this.createNotification({
          message: this.$t('clients.new.addSuccess'),
          type: 'success'
        });
        this.$emit('create-client');
        this.close();
      } catch (e) {
        this.createNotification({
          message: this.$t('clients.new.addError'),
          type: 'error'
        });
      }
      this.loading = false;
    }
  }
});
