
import { defineComponent, PropType } from 'vue';
import {
  DmTable,
  DmAsyncContent
} from '@dobrymechanik/vue-ui';
import {
  AppTextHighlighter,
  AppPhoneNumber
} from '@/components';
import {
  ClientBrowserResult,
  Client
} from '@/api/models';
import ClientTableDetails from './Details';
import {
  URI_CLIENT
} from '@/api/endpoints';

interface TableItem {
  id: string;
  name: string | null;
  phone: string;
  email: string | null;
  lastRepair: string;
}

export default defineComponent({
  name: 'ClientsTable',
  components: {
    DmTable,
    DmAsyncContent,
    AppTextHighlighter,
    AppPhoneNumber,
    ClientTableDetails
  },
  props: {
    items: {
      type: Array as PropType<ClientBrowserResult[]>,
      required: true
    },
    searchText: {
      type: String as PropType<string>,
      required: true
    }
  },
  emits: ['edit-client'],
  data () {
    return {
      expandedClientId: null as string | null,
      expandedClient: null as Client | null,
      isClientLoading: false as boolean,
      isClientError: false as boolean
    };
  },
  computed: {
    headers (): any[] {
      return [
        {
          text: this.$t('clients.name'),
          name: 'name'
        },
        {
          text: this.$t('clients.phone'),
          name: 'phone'
        },
        {
          text: this.$t('clients.email'),
          name: 'email'
        },
        {
          text: this.$t('clients.lastRepair'),
          name: 'lastRepair'
        }
      ];
    },
    tableItems (): TableItem[] {
      return this.items.map(i => ({
        id: i.id,
        name: i.name,
        phone: i.phone,
        email: i.email,
        lastRepair: this.formatDate(i.recentRepair)
      }));
    }
  },
  methods: {
    formatDate (dateString: string | null): string {
      if (dateString) {
        const date = Date.parse(dateString);
        return Intl.DateTimeFormat(this.$i18n.locale).format(date);
      }
      return '';
    },
    expandClientDetails (item: TableItem): void {
      this.expandedClientId = item.id;
      this.fetchClientDetails();
    },
    async fetchClientDetails (): Promise<void> {
      if (this.expandedClientId) {
        this.isClientLoading = true;
        this.isClientError = false;
        try {
          this.expandedClient = (await this.$http.get<Client>(URI_CLIENT(this.expandedClientId))).data;
          this.isClientError = false;
        } catch (e) {
          this.isClientError = true;
        }
        this.isClientLoading = false;
      }
    },
    onClientEdit (): void {
      this.$emit('edit-client');
      this.fetchClientDetails();
    }
  }
});
