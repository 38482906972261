import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DmButton = _resolveComponent("DmButton")
  const _component_DmInput = _resolveComponent("DmInput")
  const _component_ClientsTable = _resolveComponent("ClientsTable")
  const _component_DmInfiniteScroll = _resolveComponent("DmInfiniteScroll")
  const _component_DmTile = _resolveComponent("DmTile")
  const _component_ClientsNewModal = _resolveComponent("ClientsNewModal")
  const _component_AppWrapper = _resolveComponent("AppWrapper")

  return (_openBlock(), _createBlock(_component_AppWrapper, {
    class: _ctx.$bem({}),
    "data-test": "clients-page",
    rounded: "",
    color: "white",
    bordered: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DmTile, {
        color: "white",
        bordered: "",
        rounded: "",
        class: _ctx.$bem({e: 'table-wrapper'})
      }, {
        default: _withCtx(() => [
          _createVNode("div", {
            class: _ctx.$bem({e: 'header'})
          }, [
            _createVNode("div", {
              class: _ctx.$bem({e: 'header-chunk'})
            }, [
              (_ctx.totalItems !== null)
                ? (_openBlock(), _createBlock("span", {
                    key: 0,
                    class: _ctx.$bem({e: 'clients-counter'}),
                    "data-test": "clients-counter"
                  }, _toDisplayString(_ctx.$tc('clients.results', _ctx.totalItems)), 3))
                : _createCommentVNode("", true),
              _createVNode(_component_DmButton, {
                color: "primary",
                dark: "",
                rounded: "",
                onClick: _ctx.openNewClientModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('clients.add')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ], 2),
            _createVNode("div", {
              class: _ctx.$bem({e: 'header-chunk'})
            }, [
              _createVNode(_component_DmInput, {
                ref: "searchInput",
                modelValue: _ctx.searchText,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchText = $event)),
                rounded: "",
                light: "",
                "no-hint": "",
                "right-icon": _ctx.searchText.length > 0 ? 'close' : 'magnifier',
                "right-icon-clickable": _ctx.searchText.length > 0,
                placeholder: _ctx.$t('common.search'),
                "data-test": "clients-search-input",
                class: _ctx.$bem({e: 'browser'}),
                onInput: _ctx.onSearchTextInput,
                onClickRightIcon: _ctx.cancelSearchResults
              }, null, 8, ["modelValue", "right-icon", "right-icon-clickable", "placeholder", "class", "onInput", "onClickRightIcon"])
            ], 2)
          ], 2),
          _createVNode(_component_DmInfiniteScroll, {
            state: _ctx.infiniteScrollState,
            class: _ctx.$bem({e: 'infinite-scroll'}),
            tolerance: 700,
            onScrollToEnd: _ctx.getItems
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ClientsTable, {
                "search-text": _ctx.highlightText,
                items: _ctx.items,
                class: _ctx.$bem({e: 'table'}),
                onEditClient: _ctx.reload
              }, null, 8, ["search-text", "items", "class", "onEditClient"])
            ]),
            _: 1
          }, 8, ["state", "class", "onScrollToEnd"])
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_ClientsNewModal, {
        modelValue: _ctx.isNewClientModalShown,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNewClientModalShown = $event)),
        onCreateClient: _ctx.reload
      }, null, 8, ["modelValue", "onCreateClient"])
    ]),
    _: 1
  }, 8, ["class"]))
}