
import { defineComponent, inject } from 'vue';
import {
  URI_CLIENTS
} from '@/api/endpoints';
import {
  ClientBrowser,
  ClientBrowserResult
} from '@/api/models';
import {
  DmTile,
  DmInput,
  DmInfiniteScroll,
  DmButton
} from '@dobrymechanik/vue-ui';
import {
  AppWrapper
} from '@/components';
import {
  ClientsTable,
  ClientsNewModal
} from './_components';
import { mapActions } from 'vuex';
import { FETCH_READY_FOR_PICKUP_AMOUNT } from '@/store/actions';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import { Locale } from '@/locales';

enum InifiniteScrollState {
  LOADING = 'loading',
  LOADED = 'loaded',
  COMPLETE = 'complete',
  ERROR = 'error'
}

export default defineComponent({
  name: 'Clients',
  components: {
    AppWrapper,
    DmTile,
    DmInput,
    DmInfiniteScroll,
    DmButton,
    ClientsTable,
    ClientsNewModal
  },
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      items: [] as ClientBrowserResult[],
      isLoadingError: false as boolean,
      infiniteScrollState: InifiniteScrollState.LOADED as InifiniteScrollState,
      page: 0 as number,
      searchText: '' as string,
      timeout: undefined as number | undefined,
      totalItems: null as number | null,
      typing: false as boolean,
      isNewClientModalShown: false as boolean
    };
  },
  computed: {
    resultsCounterSuffix (): string {
      const rule = new Intl.PluralRules(this.$i18n.locale).select(this.totalItems || 0);
      const getCases = (locale: string) => {
        switch (locale) {
        case Locale.PL:
          return {
            one: 'wynik',
            few: 'wyniki',
            many: 'wyników'
          };
        case Locale.EN:
          return {
            one: 'result',
            other: 'results'
          };
        }
      };
      const cases: any = getCases(this.$i18n.locale);
      return cases[rule];
    },
    highlightText (): string {
      return (this.searchText.length > 2 && !this.typing) ? this.searchText : '';
    }
  },
  mounted () {
    this.focusOnSearchInput();
  },
  methods: {
    ...mapActions({
      fetchReadyForPickupAmount: FETCH_READY_FOR_PICKUP_AMOUNT
    }),
    async getItems (): Promise<void> {
      this.typing = false;
      this.infiniteScrollState = InifiniteScrollState.LOADING;
      try {
        const params: {page: number; query?: string} = {
          page: this.page + 1
        };
        if (this.searchText) {
          params.query = this.searchText;
        }
        const pageData = (await this.$http.get<ClientBrowser>(URI_CLIENTS, { params })).data;
        this.page = pageData.page;
        this.items = [...this.items, ...pageData.results];
        this.totalItems = pageData.totalItems;
        this.infiniteScrollState = pageData.page < pageData.totalPages ? InifiniteScrollState.LOADED : InifiniteScrollState.COMPLETE;
      } catch (e) {
        this.infiniteScrollState = InifiniteScrollState.ERROR;
      }
    },
    openNewClientModal (): void {
      this.isNewClientModalShown = true;
    },
    reload (): void {
      this.totalItems = null;
      this.items = [];
      this.page = 0;
      this.getItems();
    },
    reloadAll (): void {
      this.reload();
      this.fetchReadyForPickupAmount(this.$http);
    },
    onSearchTextInput (searchText: string): void {
      this.typing = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (searchText.length === 0 || searchText.length > 2) {
          this.trackEvent({
            event: TrackedEventType.CLICK,
            category: TrackedEventCategory.CLIENTS,
            action: 'Search used'
          });
          this.reload();
        }
      }, 500);
    },
    focusOnSearchInput (): void {
      const searchInput = this.$refs.searchInput as HTMLInputElement;
      if (searchInput) searchInput.focus();
    },
    cancelSearchResults (): void {
      this.searchText = '';
      this.focusOnSearchInput();
      this.reload();
    }
  }
});
