import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppModalHeader = _resolveComponent("AppModalHeader")
  const _component_AppModalButton = _resolveComponent("AppModalButton")
  const _component_AppClientForm = _resolveComponent("AppClientForm")
  const _component_AppModal = _resolveComponent("AppModal")

  return (_openBlock(), _createBlock(_component_AppModal, {
    "model-value": _ctx.modelValue,
    persistent: "",
    class: _ctx.$bem({}),
    loading: _ctx.loading,
    "data-test": "new-client-modal",
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppModalHeader, {
        title: _ctx.$t('clients.new.title'),
        "with-close-button": "",
        onClose: _ctx.close
      }, null, 8, ["title", "onClose"]),
      _createVNode("div", {
        class: _ctx.$bem({e: 'content'})
      }, [
        _createVNode(_component_AppClientForm, {
          "initial-values": _ctx.initialValues,
          onSubmit: _ctx.submit
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_AppModalButton, {
              color: "primary",
              dark: "",
              type: "submit",
              rounded: "",
              class: _ctx.$bem({e: 'submit-button'}),
              "data-test": "new-event-submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('clients.new.submit')), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        }, 8, ["initial-values", "onSubmit"])
      ], 2)
    ]),
    _: 1
  }, 8, ["model-value", "class", "loading", "onClose"]))
}